<template>
    <div class="main-wrapper">
        <div style="text-align: right">
            <el-button type="primary" @click="addBtn"><i class="el-icon-plus"></i> 添加数据中心模板</el-button>
        </div>
        <el-table :data="dataCenterList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%">
            <el-table-column prop="date_center_tpl_name" label="数据中心模板名称"></el-table-column>
            <el-table-column label="操作" align="center" width="200">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="editBtn(scope.row.data_center_tpl_id)">编辑</el-link>
                    <el-link type="danger" :underline="false" @click="delBtn(scope.row.data_center_tpl_id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0 30px"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="listPages.total"
                       @current-change="pagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import { dataCentorTplGetDataCenterTpl, dataCentorTplDelDataCenterTpl } from '@/utils/apis.js'
    export default {
        data() {
            return {
                dataCenterList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                let params = {
                    paging: 1,
                    page: this.listPages.currentPageNum,
                    pageSize: this.listPages.eachPageNum,
                }
                dataCentorTplGetDataCenterTpl(params).then((res) => {
                    this.dataCenterList = res.data.list
                    this.listPages.total = res.data.total
                    if (this.listPages.total !== 0 && this.dataCenterList.length === 0) {
                        this.listPages.currentPageNum--;
                        this.getList();
                    }
                }).catch((err) => {})
            },
            addBtn() {
                this.$router.push({
                    path: '/practice/dataStepOne',
                    query: {
                        stepActive: 1
                    }
                })
            },
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            editBtn(id) {
                this.$router.push({
                    path: '/practice/dataStepOne',
                    query: {
                        stepActive: 1,
                        data_center_tpl_id: id
                    }
                })
            },
            delBtn(id) {
                this.$confirm('是否删除该数据，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning',
                }).then(() => {
                    let params = {
                        data_center_tpl_id: id
                    }
                    dataCentorTplDelDataCenterTpl(params).then((res) => {
                        this.$message.success(res.msg)
                        this.getList()
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
    }
    .goods-wrapper {
        display: flex;
        align-items: center;
        .goods-cover {
            width: 80px;
            height: 80px;
            display: flex;
            background: #F6F6F6;
            img  {
                max-width: 100%;
                max-height: 100%;
                margin: auto;
            }
        }
        .goods-name {
            width: 1%;
            flex: 1;
            margin-left: 10px;
            font-size: 16px;
        }
    }
</style>